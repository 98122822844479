/* ======================================================================== */
/* 57. sectionHeight */
/* ======================================================================== */
@for $i from 1 through 9
	.section_h-#{$i * 100}
		height: #{$i * 100}px
		max-height: 100vh
.section_h-100vh
	height: 100vh
.section_w-container-right
	max-width: 100%
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px )
	margin-left: auto
	text-align: left
.section_w-container-left
	max-width: 100%
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 20px )
	margin-right: auto
	text-align: right

@media screen and (max-width: $xxl)
	.section_w-container-right, .section_w-container-left
		width: 100%
		text-align: center
		.section-image__caption-vertical-left, .section-image__caption-vertical-right
			position: relative
			top: auto
			left: auto
			right: auto
			bottom: auto
			transform: none
			padding: 0 20px
			margin-top: 1em
			text-align: center
			width: 100%
		.section-image__caption
			&.text-left, &.text-center, &.text-right
				text-align: center !important

@media screen and (max-width: $sm)
	@for $i from 1 through 9
		.section_h-#{$i * 100}
			max-height: 70vh
